import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
// import httpBuildQuery from 'http-build-query'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const GoalBonusService = {

  /**
   * http://localhost/api/V1/goal
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getGoalByYear (year) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/goal-bonus/${year}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * http://localhost/api/V1/goal
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getGoalByYearSave (goal) {
    // let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/goal-bonus/${goal.year}`, goal).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  deleteGoalByYear (year) {
    // Todo validate form data
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/goal-bonus/${year}`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default GoalBonusService
